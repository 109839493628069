import React, { Component } from 'react';

class Warenkorb extends Component {
    state = {
        
      } 
    render() { 
        return <div className='warenkorb'>
            <h2>Warenkorb</h2>
            {this.props.items.map(item =><div key={item.name}>{ item.amount} x { item.name} { item.price } €</div>)}
        </div>;
    }
}
 
export default Warenkorb