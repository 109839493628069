import React, { Component } from 'react';
import Navbar from "./components/navbar"
import Product from "./components/product"
import Warenkorb from './components/warenkorb';

class App extends Component {
    state = { 
        items: []
     } 

    addItem = (amount, name, price) => {
        let currentItems = this.state.items;

        let existingItem = this.state.items.find(item => item.name === name);
        if(existingItem) {
            existingItem.amount++;
            existingItem.price = (existingItem.amount * price).toFixed(2);
            
        } else {
        currentItems.push({
            amount, 
            name, 
            price: (amount * price).toFixed(2)
        });}
        this.setState({ items: currentItems });

    }

    render() { 
        return <React.Fragment>
        <Navbar/>
        <div className='main-container'>
        <div className='product-container'>
        <Product onAdd={() => this.addItem(1, 'Test', 2.00)} title="Test" img="assets/img/W-P-T-228x228.png" desc="Zugriff auf die Verwaltungsseite des Streams
10 Zuhörer 
1 GB AutoDJ-Speicher 
"/>
        <Product onAdd={() => this.addItem(1, 'Starter', 12.99)} title="Starter" img="assets/img/W-P-S-228x228.png" desc="Zugriff auf die Verwaltungsseite des Streams
10 Zuhörer 
1 GB AutoDJ-Speicher"/>
        <Product onAdd={() => this.addItem(1, 'Fortgeschritten', 18.50)} title="Fortgeschritten" img="assets/img/W-P-F-228x228.png" desc="Zugriff auf die Verwaltungsseite des Streams
10 Zuhörer 
1 GB AutoDJ-Speicher "/>
        <Product onAdd={() => this.addItem(1, 'Profi', 27.50)} title="Profi" img="assets/img/W-P-P-228x228.png" desc="Zugriff auf die Verwaltungsseite des Streams
10 Zuhörer 
1 GB AutoDJ-Speicher "/>
        </div>
        <Warenkorb items={this.state.items}/>
        </div>
        </React.Fragment>
        ;
    }
}
 
export default App